"use client";
import React, { FC, useEffect, useState, useCallback } from "react";
import { getKOTH } from '../../../../src/modules/clients/vercel';
  
// Utility function to convert IPFS URI to a usable URL
const convertIpfsUriToUrl = (ipfsUri: string): string => {
    const ipfsHash = ipfsUri.split('/ipfs/')[1];
    return `https://pump.mypinata.cloud/ipfs/${ipfsHash}`;
};

export const KOTH: FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [initialized, setInitilized] = useState<boolean>(false);
    const [koth, setKOTH] = useState<any>(null);
    const [imageUrl, setImageUrl] = useState<string>('/pf-logo.png'); // Default pf-logo image
    const [isCopied, setIsCopied] = useState(false);

    // Function to handle copying to clipboard
    const copyToClipboard = () => {
        if (koth && koth.mint) {
            navigator.clipboard.writeText(koth.mint);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Reset "Copied!" status after 2 seconds
        }
    };

    const main = useCallback(async () => {
      setLoading(true);
  
      try {
        const latestKOTH = await getKOTH('false');    
        setKOTH(latestKOTH);        

        if (latestKOTH?.image_uri) {
            // Convert the IPFS URI to a URL directly
            setImageUrl(convertIpfsUriToUrl(latestKOTH.image_uri));
        }

        setLoading(false);
  
      } catch (error) {
        console.error("Error fetching KOTH data:", error);
      }
    }, [koth]);
  
    // Call main function once when cholderomponent is mounted
    useEffect(() => {
        if (!initialized) {
        main();
        setInitilized(true);
        }
    }, [main]);
    
    // Effect to handle interval polling
    useEffect(() => {
      const interval = setInterval(() => {
        main();
      }, 10000); // Fetch new pool data every 2 seconds
  
      return () => {
        clearInterval(interval); // Clean up interval on unmount
      };
    }, [main]);

    // Helper function to format address to 10 chars with ellipses in between
    const formatAddress = (address: string | any[]) => {
        if (address.length <= 8) return address;
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    };

    return (
        // <div className="z-10 max-h-[420px]">
        <div className="z-10">
            { koth ? (
                <div className="">
                    <div className="z-0">
                        <h1 className="text-md bg-[#f5f245] text-black font-semibold text-center py-2">King of the Hill</h1>
                    </div>
                    <div className="relative p-2 bg-[#1b1d28]">
                        <div className="">      

                            {/* Background */}
                            <div className="absolute inset-0 w-full h-full opacity-50 overflow-hidden">
                                <img
                                    src={imageUrl}
                                    alt={koth.name || 'Image'}
                                    className="object-cover w-full h-full"
                                    onError={() => setImageUrl('/pf-logo.png')} // Set fallback image on error
                                />
                            </div>
                            
                            {/* General Info */}
                            <div className="shadow grid grid-cols-2 md:grid-cols-1 gap-2 md:gap-0 md:space-y-2">
                                <div className="col-span-2 z-10 backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5] shadow rounded-lg p-6 flex flex-col items-center border-2 border-[#f5f245]">
                                    <div className="flex flex-row space-x-1 mb-2">
                                        <div className="">
                                            <div className="flex flex-row space-x-1">
                                                <img
                                                    src={imageUrl}
                                                    alt={koth.name || 'Image'}
                                                    className="w-24 h-24 min-w-24 min-h-24 rounded-lg"
                                                    onError={() => setImageUrl('/pf-logo.png')} // Set fallback image on error
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-row">
                                            <div className="grid grid-rows-3 mr-0">
                                                <div className="">
                                                    <a href={`https://pump.fun/${koth.mint}`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">
                                                        <img src="/pf-logo.png" alt="Logo" width={64} height={64} className="w-6 h-6" />
                                                    </a>
                                                </div>
                                                <div className="">
                                                    <a href={`https://birdeye.so/token/${koth.mint}/${koth.bonding_curve}?chain=solana`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={32} height={64} viewBox="0 0 32 32" fill="none" className="w-4 h-8">
                                                            <path d="M24.9531 13.2437C23.1094 12.1312 22.3094 9.3375 23.1219 7.4375C23.7625 5.94063 26.0688 0.625 26.0688 0.625H18.0687L0.25 36.3031C0.25 36.3031 5.24688 32.9344 12.6219 36.1656C14.9281 37.1781 17.3562 38.1344 20.0906 37.9719C26.5281 37.5875 31.675 32.6187 32.2594 26.1906C32.7781 20.5187 29.5625 16.0219 24.9563 13.2406L24.9531 13.2437ZM27.0719 25.775C26.7094 29.4375 23.7469 32.4 20.0844 32.7594C15.1344 33.25 11.0188 29.1312 11.5031 24.1781C11.8656 20.5156 14.8281 17.5531 18.4906 17.1906C23.4438 16.7031 27.5625 20.8219 27.0719 25.775Z" fill="white"></path>
                                                            <path d="M22.9875 25.3533C22.8156 27.0939 21.4063 28.5033 19.6656 28.672C17.3125 28.9064 15.3563 26.947 15.5875 24.5939C15.7594 22.8533 17.1688 21.4439 18.9094 21.272C21.2625 21.0408 23.2219 22.997 22.9875 25.3533Z" fill="#FF6D00"></path>
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div className="">
                                                    <a href={`https://solscan.io/token/${koth.mint}/${koth.bonding_curve}?chain=solana`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">
                                                        <img src="/ss-logo.png" alt="Logo" width={64} height={64} className="w-5 h-5" />
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            {(koth.twitter || koth.telegram || koth.website) && (
                                                <div className="grid grid-rows-3">
                                                    {koth.twitter && (
                                                    <div className="flex flex-row items-center space-x-1">
                                                        <a href={`${koth.twitter}`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">                                                
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="white" viewBox="0 0 12 12"><g clip-path="url(#clip0_7920_513)"><path d="M9.282 1h1.71L7.255 5.27l4.394 5.809H8.21L5.515 7.555 2.43 11.08H.721l3.995-4.567L.5 1h3.528l2.436 3.22L9.282 1zm-.6 9.056h.947L3.513 1.97H2.497l6.185 8.086z"></path></g><defs><clipPath id="clip0_7920_513"><rect width="12" height="12"></rect></clipPath></defs></svg>
                                                        </a>
                                                    </div>
                                                    )}
                                                    {koth.telegram && (
                                                    <div className="flex flex-row items-center space-x-1">
                                                        <a href={`${koth.telegram}`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">     
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="white" viewBox="0 0 12 12"><g clip-path="url(#clip0_7920_515)"><path d="M11.894 1.91l-1.8 8.487c-.134.6-.49.746-.992.465L6.36 8.842l-1.322 1.273c-.147.147-.27.27-.551.27l.196-2.793L9.764 3c.22-.196-.05-.307-.344-.11L3.138 6.844.43 6c-.588-.183-.6-.588.122-.869l10.582-4.078c.49-.183.918.11.76.857z"></path></g><defs><clipPath id="clip0_7920_515"><rect width="12" height="12"></rect></clipPath></defs></svg>
                                                        </a>
                                                    </div>
                                                    )}
                                                    {koth.website && (
                                                    <div className="flex flex-row items-center space-x-1">
                                                        <a href={`https://${koth.website}`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">  
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="white" viewBox="0 0 20 20"><g clip-path="url(#clip0_1553_2200)"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM6.446 2.831A8.037 8.037 0 003.07 6h2.323c.212-1.023.505-1.96.865-2.77.06-.136.123-.269.188-.399zM2 10c0-.69.088-1.36.252-2h2.842a21.008 21.008 0 000 4H2.252A8.013 8.013 0 012 10zm1.07 4a8.037 8.037 0 003.376 3.169 9.877 9.877 0 01-.188-.399c-.36-.81-.653-1.747-.865-2.77H3.07zm4.372 0c.173.732.392 1.392.643 1.958.328.738.693 1.273 1.047 1.61.35.333.641.432.868.432.227 0 .518-.1.867-.432.355-.337.72-.872 1.048-1.61.251-.566.47-1.226.643-1.958H7.442zm7.165 0a13.716 13.716 0 01-.865 2.77c-.06.136-.123.269-.188.399A8.037 8.037 0 0016.93 14h-2.323zm3.14-2h-2.841a21.027 21.027 0 000-4h2.842c.165.64.252 1.31.252 2s-.087 1.36-.252 2zm-4.851 0H7.104A18.907 18.907 0 017 10c0-.693.037-1.362.104-2h5.792c.067.638.104 1.307.104 2 0 .693-.037 1.362-.104 2zm1.71-6h2.324a8.037 8.037 0 00-3.376-3.169c.065.13.128.263.188.399.36.81.653 1.747.865 2.77zm-6.52-1.958c-.252.566-.47 1.226-.644 1.958h5.116a11.248 11.248 0 00-.643-1.958c-.328-.738-.693-1.273-1.047-1.61C10.518 2.099 10.226 2 10 2c-.227 0-.518.1-.868.432-.354.337-.719.872-1.047 1.61z"></path></g><defs><clipPath id="clip0_1553_2200"><rect width="20" height="20"></rect></clipPath></defs></svg>
                                                        </a>
                                                    </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Mint Address Display with Copy to Clipboard */}
                                    <div className="col-span-full text-sm w-full text-center relative">
                                        <p
                                            className="cursor-pointer"
                                            onClick={copyToClipboard}
                                            title={isCopied ? 'Copied!' : 'Click to copy'}
                                        >
                                            {formatAddress(koth.mint || 'N/A')}
                                        </p>
                                        {/* Tooltip */}
                                        <div
                                            className={`absolute w-max bg-gray-700 text-white text-xs rounded p-1 transition-opacity duration-300 ${
                                                isCopied ? 'opacity-1' : 'opacity-0'
                                            }`}
                                            style={{
                                                bottom: '100%',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                            }}
                                        >
                                            {isCopied ? 'Copied!' : koth.mint}
                                        </div>
                                    </div>

                                    <p className="mt-1 font-semibold text-center w-full">{koth.name || 'N/A'} ({koth.symbol || 'N/A'})</p>
                                </div>

                                {/* Rug Stats */}
                                    <div className="col-span-2 z-10 backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5] shadow rounded-lg p-6 w-full flex flex-col items-center justify-center border-2 border-[#f5f245]">
                                        {(koth.twitter || koth.telegram || koth.website) ? (
                                            <>
                                                {koth.twitter && (
                                                    <p className="w-full text-center flex flex-col">
                                                        <strong className="text-medium">Twitter:</strong>
                                                        <a href={koth.twitter} className="text-[#86EFAC] underline text-sm">{koth.twitter.slice(0,36)}</a>
                                                    </p>
                                                )}
                                                {koth.telegram && (
                                                    <p className="w-full text-center flex flex-col">
                                                        <strong className="text-medium">Telegram:</strong>
                                                        <a href={koth.telegram} className="text-[#86EFAC] underline text-sm">{koth.telegram.slice(0,36)}</a>
                                                    </p>
                                                )}
                                                {koth.website && (
                                                    <p className="w-full text-center flex flex-col">
                                                        <strong className="text-medium">Website:</strong>
                                                        <a href={koth.website} className="text-[#86EFAC] underline text-sm">{koth.website.slice(0,36)}</a>
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <p className="w-full font-semibold text-sm text-center text-[#ef8686]">No social links available</p>
                                        )}
                                    </div>
                                
                                {/* <Chart /> */}
                                { koth && (
                                    <div className="z-50 col-span-2 md:col-span-1">
                                        <div className="chart-container border-2 border-[#f5f245] h-[400px]">
                                            <iframe
                                                width="100%"
                                                height="600"
                                                src={`https://birdeye.so/tv-widget/${koth.mint}/${koth.bonding_curve}?chain=solana&viewMode=pair&chartInterval=1&chartType=CANDLE&chartTimezone=Asia%2FSingapore&chartLeftToolbar=hide&theme=dark`}
                                                frameBorder="0"
                                                allowFullScreen
                                            ></iframe>  
                                        </div>   
                                    </div>                       
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="">
                    <div className="z-0">
                        <h1 className="text-md bg-[#f5f245] text-black font-semibold text-center py-2">King of the Hill</h1>
                    </div>
                    <div className="relative p-2 bg-[#1b1d28]">
                        <div className="">                            
                            {/* Background */}
                            <div className="absolute inset-0 w-full h-full opacity-50 overflow-hidden">
                                <img
                                    src={imageUrl}
                                    // alt={koth.name || 'Image'}
                                    className="object-cover w-full h-full"
                                    onError={() => setImageUrl('/pf-logo.png')} // Set fallback image on error
                                />
                            </div>
                            
                            {/* General Info */}
                            <div className="shadow flex flex-col space-y-2">
                                <div className="z-10 backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5] shadow rounded-lg p-6 w-full flex flex-col items-center border-2 border-[#f5f245]">
                                    <img
                                        src={imageUrl}
                                        // alt={koth.name || 'Image'}
                                        className="w-24 h-24 rounded-lg"
                                        onError={() => setImageUrl('/pf-logo.png')} // Set fallback image on error
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default KOTH;
