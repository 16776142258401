import axios from 'axios';

const tokenDecimals = 10 ** 6;
const tokenTotalSupply = 1000000000 * tokenDecimals;

export async function getCoinData(mint: string) {
    try {
        const url = `https://degen-arena-api.vercel.app/api/coin?mint=${mint}`;
        let response = await axios.get(url, {
            headers: {
                // "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:125.0) Gecko/20100101 Firefox/125.0",
                // "Accept": "*/*",
                // "Accept-Language": "en-US,en;q=0.5",
                // "Accept-Encoding": "gzip, deflate, br",
                // "Referer": "https://www.pump.fun/",
                // "Origin": "https://www.pump.fun",
                // "Connection": "keep-alive",
                // "Sec-Fetch-Dest": "empty",
                // "Sec-Fetch-Mode": "cors",
                // "Sec-Fetch-Site": "cross-site",
                // "If-None-Match": 'W/"43a-tWaCcS4XujSi30IFlxDCJYxkMKg"'

                // Include other headers if necessary, but avoid unsafe ones
                'Content-Type': 'application/json',
            },
            // httpAgent: agent,  // Use the proxy agent for the request
            // httpsAgent: agent, // Use the proxy agent for HTTPS requests
        });
        if (response.status === 200) {
            // console.log('Coin data:', response.data);            
            return response.data;
        } else {
            console.error('Failed to retrieve coin data:', response.status);
            return null;
        }
    } catch (error) {
        // console.log('Coin error:', error);
        
        // console.error('Error fetching coin data:', error);
        return null;
    }
}

// getCoinData('Go1Xkn9zmqWd4RqYdxNHTsfuXgQjZFCGHVoSgpkvpump');

export async function getLastTrade() {
    try {
        const url = `https://degen-arena-api.vercel.app/api/last`;
        const response = await axios.get(url, {
            headers: {
                // "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:125.0) Gecko/20100101 Firefox/125.0",
                // "Accept": "*/*",
                // "Accept-Language": "en-US,en;q=0.5",
                // "Accept-Encoding": "gzip, deflate, br",
                // "Referer": "https://www.pump.fun/",
                // "Origin": "https://www.pump.fun",
                // "Connection": "keep-alive",
                // "Sec-Fetch-Dest": "empty",
                // "Sec-Fetch-Mode": "cors",
                // "Sec-Fetch-Site": "cross-site",
                // "If-None-Match": 'W/"43a-tWaCcS4XujSi30IFlxDCJYxkMKg"'

                // Include other headers if necessary, but avoid unsafe ones
                'Content-Type': 'application/json',
            }
        });
        
        if (response.status === 200) {
            return response.data;
        } else {
            // console.error('Failed to retrieve coin data:', response.status);
            console.error('Failed to retrieve coin data.');
            return null;
        }
    } catch (error) {
        // console.error('Error fetching coin data:', error);
        console.error('Error fetching coin data.');
        return null;
    }
}

export async function getTrades(mint: String, limit = 10, offset = 0, minimumSize = 0) {
    try {
        // Constructing the URL with the parameters
        const url = `https://degen-arena-api.vercel.app/api/trades?mint=${mint}&limit=${limit}&offset=${offset}&minimumSize=${minimumSize}`;
        
        const response = await axios.get(url, {
            headers: {
                // "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:125.0) Gecko/20100101 Firefox/125.0",
                // "Accept": "*/*",
                // "Accept-Language": "en-US,en;q=0.5",
                // "Accept-Encoding": "gzip, deflate, br",
                // "Referer": "https://www.pump.fun/",
                // "Origin": "https://www.pump.fun",
                // "Connection": "keep-alive",
                // "Sec-Fetch-Dest": "empty",
                // "Sec-Fetch-Mode": "cors",
                // "Sec-Fetch-Site": "cross-site",
                // "If-None-Match": 'W/"43a-tWaCcS4XujSi30IFlxDCJYxkMKg"'

                // Include other headers if necessary, but avoid unsafe ones
                'Content-Type': 'application/json',
            }
        });
        
        if (response.status === 200) {
            const holders = response.data;
            return holders;
        } else {
            console.error('Failed to retrieve coin data:', response.status);
            return null;
        }
    } catch (error) {
        console.error('Error fetching coin data:', error);
        return null;
    }
}

// getTrades('51ooo347EDePu5iW42F4eA76V3dYvojQbAdBAwYyvgy4', 10, 0, 0);

export async function getKOTH(includeNsfw: String) {
    try {
        // Constructing the URL with the parameters
        const url = `https://degen-arena-api.vercel.app/api/koth?includeNsfw=${includeNsfw}`;
        
        const response = await axios.get(url, {
            headers: {
                // "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:125.0) Gecko/20100101 Firefox/125.0",
                // "Accept": "*/*",
                // "Accept-Language": "en-US,en;q=0.5",
                // "Accept-Encoding": "gzip, deflate, br",
                // "Referer": "https://www.pump.fun/",
                // "Origin": "https://www.pump.fun",
                // "Connection": "keep-alive",
                // "Sec-Fetch-Dest": "empty",
                // "Sec-Fetch-Mode": "cors",
                // "Sec-Fetch-Site": "cross-site",
                // "If-None-Match": 'W/"43a-tWaCcS4XujSi30IFlxDCJYxkMKg"'

                // Include other headers if necessary, but avoid unsafe ones
                'Content-Type': 'application/json',
            }
        });
        
        if (response.status === 200) {
            const currentKoth = response.data;
            return currentKoth;
        } else {
            console.error('Failed to retrieve coin data:', response.status);
            return null;
        }
    } catch (error) {
        console.error('Error fetching coin data:', error);
        return null;
    }
}
